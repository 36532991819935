import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { noop, size } from 'lodash/fp';
import {
  func, shape, string, arrayOf,
} from 'prop-types';

import { ROUTES } from 'configs';
import { capMap } from 'utils';
import { ProductInputPlaceholder } from 'static/images';

import {
  BackLink,
  Button,
  ConfirmPopupButton,
  ImageBox,
  InfoBlock,
  PrivateComponent,
  BackLinkHeaderContainer,
  ControlsContainer,
  ContentContainer,
  ContentBox,
  InfoTitle,
} from 'components';

import {
  InfoContainer,
} from './styled';
import useHandleUpdate from '../Edit/editprop';

const ViewProduct = ({
  t,
  id,
  productName,
  category,
  productInfo,
  details,
  promotion,
  dimension,
  dimensionConditional,
  additionalFields,
  picture,
  handleDelete,
  isActive = true, // By default, products are enabled, only when we receive isActive false we consider the product disabled.
}) => {
  isActive = isActive ?? true; // Null case

  const [activeStatus, setActiveStatus] = useState(isActive);
  const [loading, setLoading] = useState(false);
  const { handleUpdate } = useHandleUpdate();


  useEffect(() => {
    setActiveStatus(!!isActive);
  }, [isActive]);

  const toggleActiveStatus = async () => {
    try {
      setLoading(true)
      // Optimistic update for user feedback
      const newStatus = !activeStatus;
      setActiveStatus(newStatus);

      // Perform the server update
      const data = await handleUpdate({
        productId: id,
        updates: { isActive: newStatus },
      });

      // Extract the updated isActive status from the raw data
      const updatedStatus = data?.updateProductProperty?.product?.isActive;
      // Sync the local state with the backend response
      if (typeof updatedStatus === "boolean") {
        setActiveStatus(updatedStatus);
        setLoading(false)
      } else {
        console.warn("Unexpected response structure:", data);
      }
    } catch (err) {
      console.error("Failed to update product:", err);

      // Revert to the previous state if the update fails
      setActiveStatus((prevStatus) => !prevStatus);
    }
  };


  return (
    <>
      <BackLink to={`${ROUTES.PRODUCTS}`} />
      <ContentBox>
        <BackLinkHeaderContainer>
          <h1>{productName}</h1>
          <PrivateComponent roles={['admin', 'vendor']} availableGroups={['advancedVendor']}>
            <ControlsContainer>
              <Button component={Link} onClick={toggleActiveStatus} disabled={loading}>
                {activeStatus ? t('product.view.disableProduct') : t('product.view.enableProduct')}
              </Button>
              <ConfirmPopupButton
                confirmButtonText={t('common.delete')}
                cancelButtonText={t('common.cancel')}
                title={t('product.view.confirmMessage')}
                onConfirm={handleDelete}
                disabled={true}
              >
                {t('product.view.deleteProduct')}
              </ConfirmPopupButton>
              <Button component={Link} to={`${ROUTES.PRODUCTS}/${id}/edit`}>
                {t('product.view.editProduct')}
              </Button>
            </ControlsContainer>
          </PrivateComponent>
        </BackLinkHeaderContainer>
      </ContentBox>
      <ContentContainer>
        <ImageBox width="504px" height="504px" src={picture} placeholder={ProductInputPlaceholder} />
        <InfoContainer>
          {capMap((value, key) => (
            <InfoBlock
              key={key}
              leftIndent="70px"
              label={t(`product.common.${key}`)}
              text={value}
              multiline={true}
            />
          ))(productInfo)}
          {!!size(promotion) && <InfoTitle>{t('product.common.promotion')}</InfoTitle>}
          {capMap((value, key) => (
            <InfoBlock
              leftIndent="70px"
              key={key}
              label={t(`product.common.${key}`)}
              text={value}
              href={value}
              multiline={false}
            />
          ))(promotion)}
          {capMap(({ name, value }) => (
            <InfoBlock
              key={name}
              leftIndent="70px"
              label={name}
              text={value}
              multiline={false}
            />
          ))(additionalFields)}
          <InfoTitle>{t('product.common.dimension')}</InfoTitle>
          {console.log('dimension', dimension)}
          {capMap((value, key) => (
            <InfoBlock
              leftIndent="70px"
              key={key}
              label={t(`product.common.${key}`)}
              text={value}
              href={value}
              multiline={false}
            />
          ))(dimension)}
          {category !== 'non-food' ? (
            <>
              {capMap((value, key) => (
                <InfoBlock
                  leftIndent="70px"
                  key={key}
                  label={t(`product.common.${key}`)}
                  text={value}
                  href={value}
                  multiline={false}
                />
              ))(dimensionConditional)}
            </>
          ) : (
            null
          )}
          {category !== 'non-food' ? (
            <>
              {!!size(details) && <InfoTitle>{t('product.common.details')}</InfoTitle>}
              {capMap((value, key) => (
                <InfoBlock
                  leftIndent="70px"
                  key={key}
                  label={t(`product.common.${key}`)}
                  text={value}
                  href={value}
                  multiline={false}
                />
              ))(details)}
            </>
          ) : (
            null
          )}
        </InfoContainer>
      </ContentContainer>
    </>
  );
}

ViewProduct.propTypes = {
  t: func.isRequired,
  id: string,
  productName: string,
  category: string,
  picture: string,
  handleDelete: func,
  productInfo: shape({
    description: string,
    category: string,
    vendor: string,
    price: string,
  }),
  dimension: shape({
    width: string,
    height: string,
    depth: string,
    package: string,
    unit: string,
  }),
  dimensionConditional: shape({
    weight: string,
  }),
  details: shape({
    originCountry: string,
    quality: string,
    storageRequirements: string,
    ingredientList: string,
    propertyList: string,
    nutritionalInfo: string,
    allergenList: string,
    biologicalSpecies: string,
  }),
  promotion: shape({
    discountPrice: string,
    discountTime: string,
  }),
  additionalFields: arrayOf(shape({})),
};

ViewProduct.defaultProps = {
  id: '',
  productName: '',
  category: '',
  picture: '',
  productInfo: {},
  details: {},
  promotion: {},
  dimension: {},
  dimensionConditional: {},
  additionalFields: [],
  handleDelete: noop,
};

export default withTranslation()(ViewProduct);
