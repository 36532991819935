import {
  get,
  compose,
  reduce,
  filter,
  getOr,
  map,
  join,
} from 'lodash/fp';
import i18n from 'i18next';

import { getSlotTypes } from 'configs/slotTypes';
import { getDropHeights } from 'configs/dropHeights';

import { getDuration } from 'utils/dates';
import { invokeIfArgExists } from 'utils/graphQL';
import { CURRENCIES } from 'configs';

const productInfo = {
  overview: [
    'description',
    'vendor',
    'category',
    'price',
    'vat'
  ],
  dimension: [
    'width',
    'height',
    'depth',
    'package',
    'slotTypes',
    'dropHeight',
  ],
  dimensionConditional: [
    'weight'
  ],
  details: [
    'shelfLife',
    'barcode',
    'originCountry',
    'quality',
    'storageRequirements',
    'ingredientList',
    'propertyList',
    'nutritionalInfo',
    'allergenList',
    'biologicalSpecies',
  ],
  promotion: [
    'discountPrice',
    'discountTime',
  ],
};

const getProductPrice = (product) => {
  const price = get('price', product);
  const currency = get('currency', product);
  const currencySymbol = get(currency, CURRENCIES);

  return `${currencySymbol} ${price}`;
};

const getProductVat = (product) => {
  const vat = get('vat', product);

  if (!vat) {
    return '-';
  }

  return `${vat} %`;
}

const getProductDiscountPrice = (product) => {
  const price = get('discountPrice', product);

  if (!price) {
    return '-';
  }

  const currency = get('currency', product);
  const currencySymbol = get(currency, CURRENCIES);

  return `${currencySymbol} ${price}`;
};

const getProductDiscountTime = (product, t) => {
  const time = get('discountTime', product);

  return getDuration(time, i18n.language, t);
};

const getProductWeight = (product) => {
  const weight = get('weight', product);
  const unit = get('unit', product);

  return `${weight} ${unit}`;
};

const getters = {
  price: getProductPrice,
  vat: getProductVat,
  discountPrice: getProductDiscountPrice,
  discountTime: getProductDiscountTime,
  weight: getProductWeight,
  vendor: get(['vendor', 'companyName']),
  category: get(['category', 'companyName']),
  slotTypes: (products, t) => {
    const slotTypes = getSlotTypes(t);
    return compose(
      join(', '),
      map((key) => slotTypes[key]),
      get(['slotTypes']),
    )(products);
  },
  dropHeight: (products, t) => {
    let dropHeight = get('dropHeight',products);
    if (!dropHeight) {
      dropHeight = 'default';
    }
    return get(dropHeight, getDropHeights(t));
  },
};


const getProductDetails = (product, productProps, t) => reduce((acc, property) => {
  const getFunc = getOr(get(property), [property], getters);
  const value = getFunc(product, t);

  if (value) {
    acc[property] = value;
  }
  return acc;
}, {}, productProps);

export const getProductData = (t) => invokeIfArgExists(compose(
  (product) => ({
    id: product.productId,
    productName: product.name,
    picture: product.picture,
    category: product.category.name,
    productInfo: getProductDetails(product, productInfo.overview, t),
    dimension: getProductDetails(product, productInfo.dimension, t),
    dimensionConditional: getProductDetails(product, productInfo.dimensionConditional, t),
    details: getProductDetails(product, productInfo.details, t),
    promotion: getProductDetails(product, productInfo.promotion, t),
    additionalFields: filter('value', product.additionalFields),
    isActive: product.isActive,
  }),
  get('product'),
));
