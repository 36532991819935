import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PRODUCT_PROPERTY } from '../queries';

const useHandleUpdate = () => {
  const [updateProductProperty, { loading, error }] = useMutation(UPDATE_PRODUCT_PROPERTY);
  const handleUpdate = async ({ productId, updates }) => {
    if (!productId) {
      throw new Error("Product ID is required to update the product.");
    }

    if (!updates || Object.keys(updates).length === 0) {
      throw new Error("At least one update field is required.");
    }

    try {
      const { data } = await updateProductProperty({
        variables: {
          input: {
            productId,
            updates,
          },
        },
      });

      return data;
    } catch (err) {
      console.error("Error updating product:", err);
      throw err; // Re-throw error for the caller to handle
    }
  };

  return {
    handleUpdate,
    loading,
    error,
  };
};

export default useHandleUpdate;
